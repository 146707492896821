:root {
  --mui-primary-color: #c62828;
  --mui-secondary-color: #3949ab;
  --primary-background-color: #f6f2f2;
  --light-background-color: #f0f0f0;
  --navbar-background-color: #f6f2f2f0;
  --light-text-color: rgba(0,0,0,0.35);
  --text-color: #202020;
  --dark-theme-text-color: #e0e0e0;
  --max-width: 780px;
}

.main {
}

.feed {
  max-width: var(--max-width);
  margin: 0 auto;
  text-align: left;
  padding: 8px;
}

.bottombar {
  padding-bottom: 56px;
}

.small {
  text-size: 12px;
}

.logo {
  font-family: "Lora", -apple-system, Arial, Helvetica, sans-serif;
  font-size: 24px;
  margin: 8px 0px;
  text-decoration: none;
  /* color: var(--mui-primary-color); */
}

.clean-link {
  text-decoration: none;
  color: inherit;
}

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--primary-background-color);
}

.bottom-content {
  display: flex;
  max-width: var(--max-width);
  padding: 8px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}


footer {
  margin: 60px 0px;
  font-size: 12px;
  color: var(--light-text-color);
}

nav.navbar {
  background-color: var(--primary-background-color);
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

